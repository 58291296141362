import React from 'react';
import StarfieldPage from './StarfieldPage';

function App() {
  return (
    <div>
      <StarfieldPage />
    </div>
  );
}

export default App;
