import React, { useState, useEffect } from 'react';

const StarfieldPage = () => {
  const [stars, setStars] = useState([]);
  
  // Initialize stars with 3D coordinates
  useEffect(() => {
    const generateStars = () => {
      const numStars = 200;
      const newStars = [];
      
      for (let i = 0; i < numStars; i++) {
        newStars.push({
          x: Math.random() * 2000 - 1000, // x coordinate in 3D space
          y: Math.random() * 2000 - 1000, // y coordinate in 3D space
          z: Math.random() * 1000,        // z coordinate (depth)
          // Store original coordinates for smooth wrapping
          originalX: 0,
          originalY: 0,
          originalZ: 0,
        });
      }
      
      // Store original positions for resetting
      newStars.forEach(star => {
        star.originalX = star.x;
        star.originalY = star.y;
        star.originalZ = star.z;
      });
      
      setStars(newStars);
    };
    
    generateStars();
  }, []);
  
  // Animate stars in 3D space
  useEffect(() => {
    const animateStars = () => {
      setStars(prevStars =>
        prevStars.map(star => {
          // Move star closer to viewer
          let z = star.z - 10;
          
          // If star is too close, reset to far position
          if (z < 1) {
            return {
              ...star,
              x: star.originalX,
              y: star.originalY,
              z: 1000,
            };
          }
          
          // Calculate perspective projection
          const k = 128.0 / z;
          const px = star.x * k + window.innerWidth / 2;
          const py = star.y * k + window.innerHeight / 2;
          
          return {
            ...star,
            z,
            px,
            py,
            size: (1000 - z) / 100, // Stars get bigger as they get closer
          };
        })
      );
    };
    
    const intervalId = setInterval(animateStars, 30);
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <div className="relative min-h-screen bg-black overflow-hidden">
      {/* Stars */}
      {stars.map((star, index) => (
        <div
          key={index}
          className="absolute rounded-full bg-white"
          style={{
            left: `${star.px}px`,
            top: `${star.py}px`,
            width: `${Math.max(0.5, star.size)}px`,
            height: `${Math.max(0.5, star.size)}px`,
            opacity: Math.min(1, star.size / 3),
          }}
        />
      ))}
      
      {/* Content */}
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center text-white p-8">
        <a href="mailto:pascal@pascaldimassimo.com">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Hello!</h1>
        </a>
      </div>
    </div>
  );
};

export default StarfieldPage;
